import React from 'react';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { Link } from 'gatsby';
import { Header, Layout } from '../components';
import FourOhFourImage from '../images/404.svg';
import * as styles from './404.module.scss';

const FourOhFour = props => {
  const { location } = props;
  return (
    <Layout location={location}>
      <div className={`${styles.notFoundWrap} wrapper`}>
        <img className={styles.notFoundImage} src={FourOhFourImage} alt="Oops this page has gone missing" />
        <div className={styles.notFoundText}>
          <h2>404 Not found</h2>
          <Link className={`${styles.button} button`} to="/">
            Go back home
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicUnpublishedPreview(FourOhFour);
